/* inter-400 - latin */
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	unicode-range: U+000-5FF;
	src: local('Inter'),
		url('/assets/fonts/inter-v3-latin-400.woff2') format('woff2'),
		url('/assets/fonts/inter-v3-latin-400.woff') format('woff');
}

/* inter-500 - latin */
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	unicode-range: U+000-5FF;
	src: local('Inter'),
		url('/assets/fonts/inter-v3-latin-500.woff2') format('woff2'),
		url('/assets/fonts/inter-v3-latin-500.woff') format('woff');
}

/* inter-600 - latin */
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	unicode-range: U+000-5FF;
	src: local('Inter'),
		url('/assets/fonts/inter-v3-latin-600.woff2') format('woff2'),
		url('/assets/fonts/inter-v3-latin-600.woff') format('woff');
}

/* inter-700 - latin */
@font-face {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	unicode-range: U+000-5FF;
	src: local('Inter'),
		url('/assets/fonts/inter-v3-latin-700.woff2') format('woff2'),
		url('/assets/fonts/inter-v3-latin-700.woff') format('woff');
}

/* montserrat-400 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	unicode-range: U+000-5FF;
	src: local('Montserrat'),
		url('/assets/fonts/montserrat-v15-latin-400.woff2') format('woff2'),
		url('/assets/fonts/montserrat-v15-latin-400.woff') format('woff');
}

/* montserrat-600 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-display: block;
	unicode-range: U+000-5FF;
	src: local('Montserrat'),
		url('/assets/fonts/montserrat-v15-latin-600.woff2') format('woff2'),
		url('/assets/fonts/montserrat-v15-latin-600.woff') format('woff');
}

/* oswald-500 - latin */
@font-face {
	font-family: 'Oswald';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	unicode-range: U+000-5FF;
	src: local('Oswald'),
		url('/assets/fonts/oswald-v36-latin-500.woff2') format('woff2'),
		url('/assets/fonts/oswald-v36-latin-500.woff') format('woff');
}

/* playfair-display-400 - latin */
@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	unicode-range: U+000-5FF;
	src: local('Playfair Display'),
		url('/assets/fonts/playfair-display-v22-latin-400.woff2') format('woff2'),
		url('/assets/fonts/playfair-display-v22-latin-400.woff') format('woff');
}
