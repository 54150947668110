@import 'fonts';
@import 'helper';


// @media screen and (min-width: 768px) {
//   html {
//     display: flex;
//     justify-content: center;
//     padding-top: 5%;
//     padding-bottom: 5%;
//     background-color: #95a5a6;
//   }

//   body {
//   	max-width: 414px;
//     max-height: calc(100vh - (100vw * 0.1));
//   }
// }

ion-content {
	--padding-top: calc(var(--ion-safe-area-top, 0) - 14px);
	--background: #ecf0f1;

	ion-header + & {
		--padding-top: 0;
	}
}

ion-list {
	app-expandable-card ~ &,
	ion-card ~ &,
	ion-content > & {
		--ion-item-background: #ecf0f1;
	}
}

app-expandable-card {
	ion-item {
		--padding-start: 0;
		--inner-padding-end: 0;
	}
}

ion-card.hydrated {
	--background: #fefefe;
}

ion-list.list {
	background-color: transparent;

	ion-item {
		--background: transparent;
	}
}

.image-versions-modal {
	.swiper-pagination-bullets {
		bottom: 30px;
	}
}

// fix ion-select positioning
ion-select {
	position: relative;
}

// product images AutoEdit acceptance alert styles
.auto-edit-alert {
	.alert-message {
		display: flex;
		justify-content: center;
		padding-top: 9px;
		padding-bottom: 16px;

		img {
			object-fit: contain;
			background-image: url(data:image/jpeg;charset=utf-8;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/wgALCAAeAB4BAREA/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAYDBAcB/9oACAEBAAAAAdltPQi2GsngQuaLAhH/xAAcEAACAgIDAAAAAAAAAAAAAAADEAAEEzMCFDT/2gAIAQEAAQUClX0rqmleuTgfOOZxo+ln0r//xAAeEAABAwQDAAAAAAAAAAAAAAACAAEDEDNykREgMf/aAAgBAQAGPwJR5VtlpARATMz+8K4O1cHdJMX6SYvX/8QAHBAAAgEFAQAAAAAAAAAAAAAAARAAICFR8PGh/9oACAEBAAE/IZ4VBgSWklYBODnFrUYRWow//9oACAEBAAAAEP8A3P/EAB4QAAIBBAMBAAAAAAAAAAAAAAAB8BARIVExQcHx/9oACAEBAAE/ECd3X7UZpM0kHlt9IiHpAPaQGwlgTFIDYXBa5//Z);
			background-repeat: repeat;
		}
	}
}

.app-modal {
	--max-height: calc(100vh - 40px);

	flex-direction: column;
	justify-content: flex-end;

	.modal-shadow {
		pointer-events: none;
	}

	.modal-wrapper {
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
	}

	ion-content {
		--background: #ffffff;
	}
}

.app-modal--half {
	--max-height: 50%;
}

.app-modal--two-thirds {
	--max-height: #{ 2 / 3 * 100% };
}

.custom-query-select-job {
	.alert-wrapper {
		--width: 100%;
		--max-width: 80vw;
		--height: 100%;
		--max-height: 90vh;

		.alert-radio-group {
			max-height: none;
		}
	}
}

app-copy-to-clipboard {
	margin-left: 0;
	margin-right: 0;
}

.product-title,
.product-details {
	@extend %ellipsis;
}

.product-details {
	--color: var(--ion-color-step-350, #a6a6a6);

	color: var(--color);
}
