/** 
* main text classes 
**/

$main-text:
	'body-2' 'xs',
	'body-1' 'sm',
	'title' 'md',
	'label' 'lg';

@each $name, $size in $main-text {
	.#{$name} {
		font-weight: 400;
	}

	.#{$name}-semi {
		font-weight: 500;
	}

	.#{$name}-em {
		font-weight: 600;
	}

	.#{$name}, .#{$name}-semi, .#{$name}-em {
		font-family: var(--font-text-primary);
		font-size: var(--text-size-#{$size});
		line-height: var(--text-height-#{$size});
	}
}

.headline {
	font-family: var(--font-text-primary);
	font-weight: 700;
	font-size: var(--text-size-xl);
	line-height: var(--text-height-xl);
}

/** 
* display text classes
**/

.listing-title,
.listing-title-lg {
	font-family: var(--font-display-primary);
	font-weight: 400;
}

.listing-title {
	font-size: 18px;
	line-height: 24px;
}

.listing-title-lg {
	font-size: 24px;
	line-height: 32px;
}

.listing-price,
.listing-price-lg {
	font-family: var(--font-text-secondary);
	font-weight: 500;
}

.listing-price {
	font-size: 14px;
	line-height: 21px;
}

.listing-price-lg {
	font-size: 20px;
	line-height: 30px;
}

.nav-title {
	font-family: var(--font-display-secondary);
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase;
}

.sku {
	font-family: var(--font-display-secondary);
	font-weight: 600;
	font-size: 12px;
	line-height: 20px;	
}

.segmented-control {
	font-family: var(--font-text-primary);
	font-weight: 600;
	font-size: 11px;
	line-height: 13px;
	text-transform: uppercase;
}

.description-text {
	font-family: var(--font-text-primary);
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
}
