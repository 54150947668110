/**
 * Forces an element to self-clear its children
 *
 * Usage:
 * .selector {
 *   @include clearfix;
 * }
 */
@mixin clearfix() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }

  // stylelint-disable-next-line
  *zoom: 1;
}

/**
 * Hide element but make it accessible to screen readers
 *
 * Usage:
 * .selector {
 *   @include visually-hidden;
 * }
 */
@mixin visually-hidden() {
  // stylelint-disable-next-line
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}