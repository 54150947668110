// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	/***********/
	/** color **/
	/***********/

	/** primary **/
	--ion-color-primary: #3880ff;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #fff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #3171e0;
	--ion-color-primary-tint: #4c8dff;

	/** secondary **/
	--ion-color-secondary: #0cd1e8;
	--ion-color-secondary-rgb: 12, 209, 232;
	--ion-color-secondary-contrast: #fff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #0bb8cc;
	--ion-color-secondary-tint: #24d6ea;

	/** tertiary **/
	--ion-color-tertiary: #7044ff;
	--ion-color-tertiary-rgb: 112, 68, 255;
	--ion-color-tertiary-contrast: #fff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #633ce0;
	--ion-color-tertiary-tint: #7e57ff;

	/** success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #fff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #fff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #fff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 34, 34;
	--ion-color-dark-contrast: #fff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #fff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: #000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;



	/** thrilling-blue **/
	--ion-color-thrilling-blue: #79d8fb;
	--ion-color-thrilling-blue-rgb: 121,216,251;
	--ion-color-thrilling-blue-contrast: #000;
	--ion-color-thrilling-blue-contrast-rgb: 0,0,0;
	--ion-color-thrilling-blue-shade: #6abedd;
	--ion-color-thrilling-blue-tint: #86dcfb;

	/** thrilling-orange **/
	--ion-color-thrilling-orange: #ff6a4d;
	--ion-color-thrilling-orange-rgb: 255,106,77;
	--ion-color-thrilling-orange-contrast: #fff;
	--ion-color-thrilling-orange-contrast-rgb: 0,0,0;
	--ion-color-thrilling-orange-shade: #e05d44;
	--ion-color-thrilling-orange-tint: #ff8450; // thrilling-orange__light

	/** thrilling-pink **/
	--ion-color-thrilling-pink: #ffb6db;
	--ion-color-thrilling-pink-rgb: 255,182,219;
	--ion-color-thrilling-pink-contrast: #000;
	--ion-color-thrilling-pink-contrast-rgb: 0,0,0;
	--ion-color-thrilling-pink-shade: #e0a0c1;
	--ion-color-thrilling-pink-tint: #ffedf6; // thrilling-pink__light

	/** thrilling-lime **/
	--ion-color-thrilling-lime: #e5ff00;
	--ion-color-thrilling-lime-rgb: 229,255,0;
	--ion-color-thrilling-lime-contrast: #000;
	--ion-color-thrilling-lime-contrast-rgb: 0,0,0;
	--ion-color-thrilling-lime-shade: #cae000;
	--ion-color-thrilling-lime-tint: #e8ff1a;



	/** system-red **/
	--ion-color-system-red: #ff3e13;
	--ion-color-system-red-rgb: 255,62,19;
	--ion-color-system-red-contrast: #fff;
	--ion-color-system-red-contrast-rgb: 255,255,255;
	--ion-color-system-red-shade: #e03711;
	--ion-color-system-red-tint: #ff512b;

	/** system-blue **/
	--ion-color-system-blue: #1b59f8;
	--ion-color-system-blue-rgb: 27,89,248;
	--ion-color-system-blue-contrast: #fff;
	--ion-color-system-blue-contrast-rgb: 255,255,255;
	--ion-color-system-blue-shade: #184eda;
	--ion-color-system-blue-tint: #326af9;

	/** system-purple **/
	--ion-color-system-purple: #7b61ff;
	--ion-color-system-purple-rgb: 123,97,255;
	--ion-color-system-purple-contrast: #fff;
	--ion-color-system-purple-contrast-rgb: 255,255,255;
	--ion-color-system-purple-shade: #6c55e0;
	--ion-color-system-purple-tint: #8871ff;

	

	/** black **/
	--ion-color-black: #000;
	--ion-color-black-rgb: 0,0,0;
	--ion-color-black-contrast: #fff;
	--ion-color-black-contrast-rgb: 255,255,255;
	--ion-color-black-shade: #000;
	--ion-color-black-tint: #1a1a1a;

	/** white **/
	--ion-color-white: #fff;
	--ion-color-white-rgb: 255,255,255;
	--ion-color-white-contrast: #000;
	--ion-color-white-contrast-rgb: 0,0,0;
	--ion-color-white-shade: #e0e0e0;
	--ion-color-white-tint: #fff;

	// Ionic doesn't allow numbers in color names; below is a list of names with their corresponding alpha percentages for gray colors.
	// darker - 70
	// dark - 50
	// medium - 30
	// light - 20
	// lighter - 10
	// lightest - 4

	/** gray-darker **/ 
	--ion-color-gray-darker: #4d4d4d;
	--ion-color-gray-darker-rgb: 77,77,77;
	--ion-color-gray-darker-contrast: #fff;
	--ion-color-gray-darker-contrast-rgb: 255,255,255;
	--ion-color-gray-darker-shade: #444;
	--ion-color-gray-darker-tint: #5f5f5f;

	/** gray-dark **/
	--ion-color-gray-dark: #808080;
	--ion-color-gray-dark-rgb: 128,128,128;
	--ion-color-gray-dark-contrast: #fff;
	--ion-color-gray-dark-contrast-rgb: 0,0,0;
	--ion-color-gray-dark-shade: #717171;
	--ion-color-gray-dark-tint: #8d8d8d;

	/** gray-medium **/
	--ion-color-gray-medium: #b3b3b3;
	--ion-color-gray-medium-rgb: 179,179,179;
	--ion-color-gray-medium-contrast: #fff;
	--ion-color-gray-medium-contrast-rgb: 0,0,0;
	--ion-color-gray-medium-shade: #9e9e9e;
	--ion-color-gray-medium-tint: #bbb;

	/** gray-light **/
	--ion-color-gray-light: #ccc;
	--ion-color-gray-light-rgb: 204,204,204;
	--ion-color-gray-light-contrast: #000;
	--ion-color-gray-light-contrast-rgb: 0,0,0;
	--ion-color-gray-light-shade: #b4b4b4;
	--ion-color-gray-light-tint: #d1d1d1;

	/** gray-lighter **/
	--ion-color-gray-lighter: #e6e6e6;
	--ion-color-gray-lighter-rgb: 230,230,230;
	--ion-color-gray-lighter-contrast: #000;
	--ion-color-gray-lighter-contrast-rgb: 0,0,0;
	--ion-color-gray-lighter-shade: #cacaca;
	--ion-color-gray-lighter-tint: #e9e9e9;

	/** gray-lightest **/
	--ion-color-gray-lightest: #f5f5f5;
	--ion-color-gray-lightest-rgb: 245,245,245;
	--ion-color-gray-lightest-contrast: #000;
	--ion-color-gray-lightest-contrast-rgb: 0,0,0;
	--ion-color-gray-lightest-shade: #d8d8d8;
	--ion-color-gray-lightest-tint: #f6f6f6;


	/****************/
	/** typography **/
	/****************/

	/** font family **/
	--font-text-primary: 'Inter', sans-serif;
	--font-text-secondary: 'Oswald', sans-serif;
	--font-display-primary: 'Playfair Display', serif;
	--font-display-secondary: 'Montserrat', sans-serif;

	/** type scale **/
	--text-size-xs: 12px;
	--text-size-sm: 14px;
	--text-size-md: 16px;
	--text-size-lg: 17px;
	--text-size-xl: 24px;

	/** line height **/
	--text-height-xs: 15px;
	--text-height-sm: 18px;
	--text-height-md: 20px;
	--text-height-lg: 22px;
	--text-height-xl: 29px;
}
