.clearfix {
  @include clearfix;
}

.visually-hidden {
  @include visually-hidden;
}

%image-overlay-text {
  text-shadow:
    -0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.175),
    0 0.1rem 0.1rem rgba(0, 0, 0, 0.175),
    0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.175)
  ;
}

.image-overlay-text {
  @extend %image-overlay-text;
}

%ellipsis {
  @include ellipsis;
}